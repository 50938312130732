const Packages = {
  THREE_PLUS_SCREENS: '3-plus-screens',
  TWO_SCREENS: '2-screens',
  ONE_SCREEN: '1-screen',
  STARTER_PLAN_YEARLY: 'starter-plan-yearly',
  MULTI_PLAN_YEARLY: 'multi-plan-yearly',
  NETWORK_PLAN_YEARLY: 'network-plan-yearly',
  POWER_SAVER: 'power-saver',
  AGENCY: 'agency',
}

export default Packages
